
<div class="toolbar">
  <div class="wrapper restaurant-selector-toolbar">
    <div *ngIf="!loading" class="flex">

      <div class="left" (click)="showList()">
        <div *ngIf="selected?.logo" class="logo" style="background-image: url({{selected.logo}})"></div>
        <div class="relative">
          <h6 class="inline-block" *ngIf="!isGroupPage">{{selected?.name ?? 'No restaurant selected'}}</h6>
					<h6 class="inline-block" *ngIf="isGroupPage">{{selected?.name ?? 'No group selected'}}</h6>
          <button class="btn-switch showDesktopInline" id="btnSwitchRestaurant" userColor>Switch {{isGroupPage ? 'group' : 'restaurant'}}</button>
          <!-- *ngIf="hasMoreRestaurants" -->
          <div class="info">
						<ng-container *ngIf="selected?.restaurants && isGroupPage">
							<div class="rating-desc">
								{{ selected?.restaurants.length || 0 }} Restaurant{{ selected?.restaurants.length > 1 ? 's' : ''}}
							</div>
						</ng-container>
            <address *ngIf="selected?.street_number">
              <span class="mdi mdi-map-marker"></span>
              {{selected.street_number}} {{selected?.street_name}}, {{selected?.suburb}}
            </address>
            <ng-container *ngIf="selected?.food_rating && selected?.food_ratings_count > 49">
              <div class="rating-stars">
                <span class="mdi" [class]="selected.food_rating < 1 ? (selected.food_rating > 0 ? 'mdi-star-half-full' : 'mdi-star-outline') : 'mdi-star'"></span>
                <span class="mdi" [class]="selected.food_rating < 2 ? (selected.food_rating > 1 ? 'mdi-star-half-full' : 'mdi-star-outline') : 'mdi-star'"></span>
                <span class="mdi" [class]="selected.food_rating < 3 ? (selected.food_rating > 2 ? 'mdi-star-half-full' : 'mdi-star-outline') : 'mdi-star'"></span>
                <span class="mdi" [class]="selected.food_rating < 4 ? (selected.food_rating > 3 ? 'mdi-star-half-full' : 'mdi-star-outline') : 'mdi-star'"></span>
                <span class="mdi" [class]="selected.food_rating < 5 ? (selected.food_rating > 4 ? 'mdi-star-half-full' : 'mdi-star-outline') : 'mdi-star'"></span>
              </div>
              <div class="rating-desc">
                {{selected?.food_rating.toFixed(1)}} ({{selected?.food_ratings_count | restaurantRatingCount}} ratings)
              </div>
            </ng-container>
          </div>
          <button *ngIf="hasMoreRestaurants" class="btn-switch showMobile" id="btnSwitchRestaurant" userColor>Switch Restaurant</button>
        </div>
      </div>

      <ng-container *ngIf="!hideCreatePromoBtn">
        <a *appMayView="['promotion_create']" class="btn secondary" (click)="logEvent_CreatePromo()" [routerLink]="['/promos','new']" id="lnkNew">
          Create promotion <span class="mdi mdi-plus"></span>
        </a>
      </ng-container>

    </div>
    <div *ngIf="loading" class="loadingText">Loading...</div>
  </div>
</div>

<div modal [(show)]="isListVisible" [title]="modalTitle" [allowInternalHiding]="!!selected" align="right">

  <div class="restaurant-list-main">
    <form [formGroup]="filter"> <!-- *ngIf="(page$ | async)?.total > 9" -->
      <div class="form-wrapper">
        <div class="form-group full-row">
          <div class="form-row full-row">
            <input type="text" id="inputRestaurantNameSearch" formControlName="name" placeholder=" ">
            <label for="inputRestaurantNameSearch">Search</label>
          </div>
        </div>
      </div>
    </form>

    <div *ngIf="page$ | async as page">
      <div class="scrollable">

        <label>
          <input name="restaurant" type="radio" value="">
        </label>

        <ng-container>
          <label *ngFor="let r of page.list" class="restaurant-list flex" [class.disabled]="selected?.id===r.id">
            <input name="restaurant" type="radio" [value]="r" [(ngModel)]="selected" (ngModelChange)="select($event)">
            <div class="res-img" *ngIf="r.logo" style="background-image: url({{r.logo}});"></div>
            <div class="col-inner flex">
              <div class="col-top">
              <h6>{{r.name}}</h6>
              </div>
              <div class="col-bottom flex">
								<ng-container *ngIf="isGroupPage">&nbsp;</ng-container>
								<ng-container *ngIf="!isGroupPage">
                	<address>{{r.street_number}} {{r.street_name}}, {{r.suburb}}</address>
								</ng-container>
              </div>
            </div>
          </label>
        </ng-container>

      </div>

      <div class="pagination flex" *ngIf="page.total > page.list.length">
        <div class="desktop-pager">
          <div class="pager-showing">{{page.list.length}} of {{page.total}}</div>
        </div>
      </div>
    </div>
  </div>

</div>
