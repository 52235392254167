import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, filter, switchMap, tap } from 'rxjs/operators';

import { RestaurantService } from 'src/app/services/api/restaurant.service';
import { UserService } from 'src/app/services/api/user.service';
import { allowedRoles, BalanceDto, UrlDto } from './adverts.utils';

@Injectable({
  providedIn: 'root',
  })
export class AdvertsService {
  readonly restaurant$ = this.restaurantAPI.selectedRestaurant$;
  private query$ = of('');

  constructor(
    private readonly http: HttpClient,
    private readonly userAPI: UserService,
    private readonly restaurantAPI: RestaurantService,
  ) {
    const restaurant$ = this.restaurantAPI.selectedRestaurant$;
    const user$ = this.userAPI.getProfile();
    this.query$ = combineLatest([restaurant$, user$]).pipe(
      filter(([restaurant]) => !!restaurant),
      map(
        ([restaurant, user]) => `?restaurant_id=${restaurant.id}&user_id=${user.id}`,
      ),
    );
  }

  get allowed$(): Observable<boolean> {
    return combineLatest([
      this.userAPI.profile,
      this.restaurant$,
      this.getWhitelist(),
    ]).pipe(
      map(([user, restaurant, whitelist]) => user
        && allowedRoles.includes(user.profile?.role_name)
        && (whitelist.includes(restaurant?.id) || whitelist[0] === '*')),
    );
  }

  getWhitelist() {
    return this.http.get<string[]>('self://api/config/adverts-whitelist');
  }

  getIframeUrl() {
    return this.query$.pipe(
      switchMap((q) => this.http
        .get(`api://onlinesales/iframe${q}`)
        .pipe(map((res: UrlDto) => res.redirectUrl))),
    );
  }

  getBalance() {
    return this.query$.pipe(
      switchMap((q) => this.http.post<BalanceDto>(`api://onlinesales/balance${q}`, {})),
    );
  }

  topUp(amount: number) {
    return this.query$.pipe(
      switchMap((query) => {
        const q = query.concat('&withdraw=True').concat(`&amount=${amount}`);
        return this.http.post(`api://onlinesales/wallet/transaction${q}`, {});
      }),
    );
  }
}
