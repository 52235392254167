<div class="page-login">
  <!-- Page Body Here -->

    <div class="landing-body">
        <div class="container flex">

            <div class="col left">
              <a href="" class="site-logo" id="lnkLogoHome">
                  <img src="assets/logo-square.svg" alt="mrd-rssp">
              </a>

              <div class="reset-password">
                <h4 *ngIf="welcome">Let's set you up with a password</h4>
                <h4 *ngIf="!welcome">Let’s reset your password </h4>
                  <p>Choose a secure password that is 8 characters long, has at least one number, one upper-case letter and one special character</p>
                  

                  <div class="form-wrapper">

                    <div *ngIf="isInvalidId" class="form-error-main">
                      <span class="mdi mdi-close"></span>
                      <div>This form is no longer valid. Please request a new link.</div>
                      <a [routerLink]="['/forgot-password']" id="lnkResetPassword">Reset password</a>
                    </div>
                    
                    <div *ngIf="somethingWentWrong" class="form-error-main something-wrong">
                      Oops, something's gone wrong on our side while trying to change your password. Please try again or contact support.
                    </div>

                    <form [formGroup]="form" (ngSubmit)="onSubmit()">

                      <form-row focusClass="focused">
                        <input appInputRef type="password" id="password" formControlName="password" (focus)="onFocusPassword()"
                        [ngClass]="{ 'is-invalid': submitted && form.controls.password.errors }" placeholder=" " autocomplete="new-password" />
                        <label for="password">Enter Password</label>
                      </form-row>
                      <div *ngIf="submitted && form.controls.password.errors" class="password-error">
                          <div *ngIf="form.controls.password.errors.required">Please enter a password</div>
                          <div class="password-strength" [ngClass]="form.value.password | passwordStrength">{{form.value.password | passwordStrength}}</div>
                          <div *ngIf="form.controls.password.errors.server">{{form.controls.password.errors.server}}</div>
                        </div>
                        <div class="password-error" *ngIf="showGuides">
                          <div [ngClass]="form.value.password | passwordStrength">
                            <span class="mdi mdi-close"></span>
                            Password strength {{form.value.password | passwordStrength}}
                          </div>
                          <div [class.complete]="passwordHasUpper">
                            <span class="mdi mdi-close"></span>
                            1 upper case character
                          </div>
                          <div [class.complete]="passwordHasSpecial">
                            <span class="mdi mdi-close"></span>
                            1 special character
                          </div>
                          <div [class.complete]="passwordHasNumber">
                            <span class="mdi mdi-close"></span>
                            1 number
                          </div>
                        </div>
                      <!-- <div *ngIf="submitted && form.controls.password.errors">
                        <div *ngIf="form.controls.password.errors.minlength" class="password-error">
                          Password must be at least 8 characters long <br>
                          Password must have at least one letter <br>
                          Password must have at least one number
                        </div>
                      </div> -->

                      <form-row focusClass="focused">
                        <input appInputRef type="password" id="confirmpassword" formControlName="passwordConfirm"
                        [ngClass]="{ 'is-invalid': submitted && form.controls.passwordConfirm.errors }" placeholder=" " autocomplete="new-password"/>
                        <label for="confirmpassword">Confirm Password</label>

                        <div *ngIf="submitted && form.controls.passwordConfirm.errors" class="frm_error">
                          <div *ngIf="form.controls.passwordConfirm.errors.required">Please re-enter password</div>
                          <div *ngIf="form.controls.passwordConfirm.errors.mustMatch">Passwords do not match</div>
                        </div>
                      </form-row>

                      <div class="form-row terms">

                        <p>By signing up I agree to the <a (click)="showTC()" id="btnTCs">terms of use</a> and processing of my personal data as stated in the privacy policy.</p>
                      </div>

                      <div *ngIf="serverErrorMsg" class="password-error frm_error">{{serverErrorMsg}}</div>

                      <div class="form-row submit buttons">
                        <button class="btn button-dark" type="submit" id="btnSetPassword">Set password</button>
                      </div>

                      <div *ngIf="serverAccepted" class="frm_success">Password reset successful. Returning to login...</div>
                    
                    </form>

                  </div>
              </div>
            </div>

            <div class="col right">
                <div class="landing-image">
                    <img src="assets/landing/landing.png" alt="image">
                </div>
            </div>

        </div>
    </div>
</div>


